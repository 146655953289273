import React from 'react';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Form from 'components/Homepage/Form';

import logo from 'assets/images/homepage/logo_payzen_color.svg';

const Banner = () => (
  <div className="homepage-banner d-flex justify-content-center align-items-center">
    <div className="wrapper homepage-wrapper">
      <div className="mb-6 mb-md-9">
        <img src={logo} alt="" id="payzen-logo" />
      </div>
      <div className="row align-items-center">
        <div className="col-md-6 col-lg-7 mb-5 md-md-0">
          <div>
            <h1 className="homepage-banner__title font-weight-light mb-2 mb-md-3 text-white">
              Break Free From
              <br />
              Medical Bills
            </h1>
            <p className="homepage-banner__subtitle font mb-5 text-white font-weight-normal pr-md-15">
              Get your bill forgiven or pay what you owe at
              a rate you can afford. Never pay interest or fees.
            </p>
            <Button
              id="youtube-video"
              type="outline-white"
              className="px-md-10 text-md-larger d-flex align-items-center btn-block btn-md-auto justify-content-center"
            >
              <Icon name="play" className="mr-3" />
              <span>Watch the process</span>
            </Button>
          </div>
        </div>
        <div className="col-md-6 col-lg-5">
          <div className="bg-mariner homepage-banner__form">
            <p className="text-large text-xl-xlarge text-white d-none d-md-block">
              <i className="homepage-banner__legend">
                Resolve your medical bills in 1 min
              </i>
            </p>
            <Form
              buttonClass="text-larger font-weight-bold"
              buttonText="Get Started"
            />
            <div id="get-started" />
          </div>
        </div>
      </div>
    </div>
    <div className="scroll-down-icon" />
  </div>
);

export default Banner;
